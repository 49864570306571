import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import OverviewBlog from './OverviewBlog'
import OverviewLocations from './OverviewLocations'
import OverviewPerCategory from './OverviewPerCategory'

export default function OverviewShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    blog: OverviewBlog,
    locations: OverviewLocations,
    per_category: OverviewPerCategory
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
