import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'

export default function FormCta({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section data-component="FormCta" className="section">
      <div className="container">
        <Content className="content lg:children-h2:!text-2xl">{fields?.description}</Content>

        <div className="form-cta relative mx-auto mt-10 overflow-hidden max-lg:max-w-lg">
          <Form
            data={fields.form}
            scrollToConfirm={false}
            renderLoading={() => (
              <div className="absolute inset-0 flex items-center justify-center bg-gp-joltik/75">
                <Loading />
              </div>
            )}
            renderStatus={({ content }) => <Content className="mx-auto w-1/2">{content}</Content>}
          />
        </div>
      </div>
    </section>
  )
}
