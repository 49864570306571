/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'
import InputSearch from '~/components/elements/InputSearch'
import Chevron from '~/components/elements/svg/Chevron'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [isOpen, setOpen] = useState(-1)

  return (
    <>
      {header?.menuHeader?.map((edge, index) => (
        <motion.li onHoverStart={() => setOpen(index)} onHoverEnd={() => setOpen(-1)} className="relative text-gp-orbeeltle" key={index}>
          <div className="flex flex-wrap items-center py-2 lg:py-0">
            <LosseLink
              className={clsx(
                edge?.linkType === 'button'
                  ? 'btn my-2 w-52 lg:my-0 lg:w-auto lg:text-sm xl:text-base'
                  : 'btn--pseudo px-2 text-2xl font-bold lg:text-sm xl:text-lg'
              )}
              to={edge?.link?.url || '/'}
            >
              {edge?.link?.title}
            </LosseLink>
            {edge?.submenu && (
              <div
                onClick={isOpen === index ? () => setOpen(-1) : () => setOpen(index)}
                className="ml-1"
                aria-label={`Submenu ${edge?.link?.title}`}
              >
                <Chevron />
              </div>
            )}
          </div>
          {edge?.submenu && isOpen === index && (
            <AnimatePresence>
              <HeaderSubMenu fields={edge.submenu} />
            </AnimatePresence>
          )}
        </motion.li>
      ))}
      <li>
        <InputSearch />
      </li>
    </>
  )
}
