import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'
import FsLightbox from 'fslightbox-react'
import { useState } from 'react'

const HIGHLIGHT = [0, 1, 5, 6, 10, 11]

export default function GalleryDefault({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  const [toggler, setToggler] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <section data-component="GalleryDefault" className="section">
      <FsLightbox sources={fields.gallery?.map((image) => image?.mediaItemUrl || '')} slide={currentSlide} toggler={toggler} />
      <div className="container">
        <div className="grid grid-cols-12 gap-2 sm:gap-4 lg:gap-8">
          {fields.gallery?.map((image, index) => {
            const highlighted = HIGHLIGHT.includes(index)

            return (
              <button
                key={image?.mediaItemUrl}
                type="button"
                onClick={() => {
                  setCurrentSlide(index + 1)
                  setToggler(!toggler)
                }}
                className={clsx(highlighted ? 'col-span-6' : 'col-span-4')}
              >
                <LossePlaatjie
                  src={image}
                  maxWidth={800}
                  className={clsx(highlighted ? 'aspect-video' : 'aspect-square', 'image--rounded object-cover')}
                />
              </button>
            )
          })}
        </div>
      </div>
    </section>
  )
}
