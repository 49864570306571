import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function ContentCTA({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="ContentCTA" className="section">
      <div className="container xl:max-w-screen-xl xl:px-20">
        <div className="cta--block grid gap-6 md:grid-cols-3 md:gap-10">
          {fields?.description && (
            <div className="order-2 flex items-center md:col-span-2">
              <Content className="content lg:children-h2:!text-2xl [&_*]:max-md:!text-center">{fields.description}</Content>
            </div>
          )}
          {fields?.link && (
            <div
              className={clsx(
                'order-3 flex items-center justify-center md:col-span-1 md:justify-end',
                fields.textPosition === 'right' ? 'md:order-1' : 'md:order-3'
              )}
            >
              <LosseLink src={fields.link} className="btn" target={fields.link.target ?? '_self'} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
