import { useLosseLayout } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import HeaderMenu from './HeaderMenu'

export default function HeaderDesktop() {
  const { isSticky } = useLosseLayout()

  return (
    <ul
      className={clsx(
        !isSticky && 'lg:translate-y-7',
        'relative z-[99] mb-0 hidden w-full list-none items-center justify-end transition-all lg:flex max-lg:[&_li]:ml-4 lg:[&_li]:ml-2 xl:[&_li]:ml-6'
      )}
    >
      <HeaderMenu />
    </ul>
  )
}
