import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Product as Props } from '~/graphql/types'

export default function Product({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <div id={data.title}>
      <LossePlaatjie
        src={data.featuredImage?.node}
        className="aspect-video w-full overflow-hidden rounded-xl object-cover"
        maxWidth={600}
        title={data.title}
      />
      <h2 className="text-lg font-bold xl:text-xl">{data.title}</h2>
      <Content className="content mt-4">{data.recap?.description}</Content>
    </div>
  )
}
