import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import Email from '../elements/svg/Email'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer className="bg-gp-rookidee text-white">
      <div className="container py-5">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1 grid gap-7 py-4 sm:grid-cols-2 lg:py-20">
            <div className="col-span-1">
              <div className="mb-4">
                <h2 className="mb-4 font-bold">Contact</h2>
                <a href={`mailto:${footer.contactFooter?.email}`} className="lnk" target="_blank" rel="noreferrer">
                  <Email className="h-auto min-w-[19px]" />
                  <span>{footer.contactFooter?.email}</span>
                </a>
              </div>
              <div>
                <h2 className="mb-4 font-bold">Social media</h2>
                <div className="flex">
                  {footer.contactFooter?.socials?.map((edge) => {
                    if (!edge?.link?.url) return null

                    return (
                      <a key={edge?.link?.title} target="_blank" rel="noreferrer" href={edge.link.url} className="mr-7">
                        <LossePlaatjie src={edge.icon} />
                        <span className="hidden">{edge.link.title}</span>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <h2 className="mb-4 font-bold">Menu</h2>
              <nav>
                <ul className="flex flex-col flex-wrap lg:max-h-[130px]">
                  {footer?.menuFooter?.map((item, index) => (
                    <li className="py-1 md:mr-6 lg:mr-12" key={index}>
                      <LosseLink className="hover:underline" to={item?.link?.url || '/'}>
                        {item?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                  <li className="py-1 md:mr-6 lg:mr-12">
                    {/* @ts-expect-error - Cookiebot is not available locally */}
                    <button onClick={() => Cookiebot.show()} className="hover:underline">
                      Cookies
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="col-span-1 flex justify-center">
            <img src="/static/logo-alt.png" alt="" className="mt-5 h-fit w-60 max-w-xs lg:mt-16 lg:w-80" />
          </div>
        </div>
        <ul className="mt-10 flex flex-wrap justify-center text-sm opacity-50 lg:mt-0 lg:justify-start">
          <li>
            <span>&copy; {new Date().getFullYear()}</span>
          </li>
          {footer.bottomLinksFooter?.map((edge) => {
            return (
              <li key={edge?.link?.title}>
                <span className="mx-1">-</span>
                <LosseLink to={edge?.link?.url} target={edge?.link?.target ?? undefined} className="hover:underline">
                  {edge?.link?.title}
                </LosseLink>
              </li>
            )
          })}
        </ul>
      </div>
    </footer>
  )
}
