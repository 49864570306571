import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'

export default function BannerVideo({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerVideo" className="section">
      <div className="container">
        <div className="relative">
          <div className="image--rounded">
            <div className="relative aspect-video bg-gp-electabuzz">
              <div className="absolute left-0 top-0 z-30 hidden h-full w-3/4 bg-gradient-to-r from-black to-transparent lg:block" />
              <div className="z-20" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src={fields.video || ''}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Grillpaleis"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
          <div className="inset-0 z-10 mt-6 lg:absolute lg:mt-0 lg:w-[55%]">
            <div className="relative flex h-full w-full flex-col justify-center sm:px-10">
              <div className="relative">
                <Content className="content lg:text-white xl:children-headings:!text-6xl">{fields.description}</Content>
                <div className="max-lg:[&>a]:btn lg:[&>a:nth-child(even)]:btn--white lg:[&>a:nth-child(odd)]:btn mt-4 flex flex-wrap lg:mt-12">
                  {fields.links?.map((edge) => {
                    return <LosseLink key={edge?.link?.title} src={edge?.link} className="mr-5 mb-2" />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
