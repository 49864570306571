import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentCTA from './ContentCTA'
import ContentSimple from './ContentSimple'
import ContentAccordion from './ContentAccordion'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    simple: ContentSimple,
    block: ContentDefault,
    cta: ContentCTA,
    accordion: ContentAccordion
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
