import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="section !pb-0">
      <div className={clsx(fields.styletype === 'default' && 'container')}>
        <div
          className={clsx(
            'relative flex min-h-[250px] w-full items-end justify-start overflow-hidden sm:min-h-[300px] lg:min-h-[350px] xl:min-h-[600px]',
            fields.styletype === 'default' && 'image--rounded'
          )}
        >
          {fields?.image && (
            <LossePlaatjie
              loading="eager"
              placeholder="blur"
              className={clsx('absolute top-0 left-0 z-0 h-full w-full object-cover')}
              src={fields.image}
              maxwidth={1920}
            />
          )}
          {fields?.description && (
            <div className={clsx('relative z-10', fields.styletype === 'default' ? 'p-4 lg:p-10 xl:p-16' : 'container')}>
              <Content className="content text-white">{fields?.description}</Content>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
