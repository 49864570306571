export default function Chevron() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" fill="none" viewBox="0 0 16 9">
      <path
        fill="#E30613"
        d="M15.061.854a1.5 1.5 0 00-2.122 0L8.353 5.439a.5.5 0 01-.707 0L3.061.854A1.5 1.5 0 00.94 2.975l4.586 4.586a3.5 3.5 0 004.95 0l4.586-4.586a1.5 1.5 0 000-2.121z"
      />
    </svg>
  )
}
