import SwiperCore from 'swiper'
import  { Autoplay } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Autoplay])

export const Slide = SwiperSlide

export default function Slider(props: SwiperProps) {
  return (
    <>
      <Swiper {...props}>{props.children}</Swiper>
    </>
  )
}
