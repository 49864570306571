import Breadcrumbs from '~/components/elements/Breadcrumbs'
import type { Page_Flexcontent_Flex_Breadcrumbs } from '~/graphql/types'

export default function BreadcrumbsDefault({ fields }: { fields: Page_Flexcontent_Flex_Breadcrumbs }) {
  return (
    <section data-component="BreadcrumbsDefault" className="section--sm">
      <div className="container">
        <Breadcrumbs />
      </div>
    </section>
  )
}
