import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <LosseLink to={data.uri}>
      <LossePlaatjie
        src={data.featuredImage?.node}
        className="w-full aspect-video rounded-xl overflow-hidden object-cover"
        maxWidth={600}
        title={data.title}
      />
      <h2 className="swiper-no-swiping text-2xl line-clamp-1">{data.title}</h2>
    </LosseLink>
  )
}
