import { type LoaderData, useLosseZoekenMoek } from '@ubo/losse-sjedel'
import { Form, LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Loading from '~/components/elements/Loading'
import type { Page, Page_Flexcontent_Flex_Search, Post } from '~/graphql/types'

const contentTypeNames: {
  [key: string]: {
    label: string
    uri: string
  }
} = {
  page: {
    label: 'pagina',
    uri: ''
  },
  locaties: {
    label: 'location',
    uri: '/locaties/'
  },
  producten: {
    label: 'product',
    uri: '/assortiment/'
  }
}

export default function Search({ fields }: { fields: Page_Flexcontent_Flex_Search }) {
  const data = useLoaderData<LoaderData>()
  const [state, result, currentValue, next, prev] = useLosseZoekenMoek()

  return (
    <section data-component="Search" className="min-h-screen">
      <div className="py-12 lg:py-20">
        <div className="container">
          <Content className="content">{fields.description}</Content>
          <div className="mt-5 h-1 w-24"></div>
        </div>
      </div>

      <div className="relative">
        <div className="container relative z-10 pb-20 xl:max-w-screen-xl">
          <div className="flex">
            <Form
              action={new URL(data.request.url).pathname}
              method="get"
              className="flex items-center overflow-hidden rounded-full shadow-lg"
            >
              <input
                type="search"
                name="q"
                placeholder="Typ hier om te zoeken..."
                defaultValue={currentValue}
                className="h-full w-96 rounded-none py-2 pl-6 pr-3"
              />
              <button type="submit" className="btn rounded-l-none py-2 px-6">
                {fields.searchbutton}
              </button>
            </Form>
          </div>

          {(state === 'loading' || state === 'submitting') && !!currentValue && (
            <div className="flex justify-center py-10">
              <Loading />
            </div>
          )}

          <div className="mt-10">
            {state === 'idle' && (
              <>
                {result?.edges?.length < 1 && <Content>{fields.noresulttext}</Content>}
                <div className="grid grid-cols-2 gap-4 sm:gap-6 lg:grid-cols-3 lg:gap-10">
                  {result?.edges?.map((edge) => {
                    if (!edge) return null
                    if (!edge.node) return null
                    const node = edge.node as Post | Page

                    return (
                      <LosseLink
                        to={
                          contentTypeNames[node.contentTypeName].uri
                            ? `${contentTypeNames[node.contentTypeName].uri}#${node.title}`
                            : node.uri
                        }
                        key={node.id}
                        className="oldskool--shadow group col-span-1 flex flex-col justify-between overflow-hidden rounded-xl bg-white"
                      >
                        <div className="p-3 lg:p-5">
                          <div className="mb-2 block text-xl font-extrabold line-clamp-2 group-hover:underline lg:text-2xl">
                            {node.title}
                          </div>
                          <div className="flex items-end justify-between">
                            <div className="mt-2 rounded-full py-1 text-[12px] font-bold uppercase text-gp-rookidee">
                              {contentTypeNames[node.contentTypeName].label || node.contentTypeName}
                            </div>
                            <div className="flex h-8 w-8 items-center justify-center rounded-full lg:h-10 lg:w-10">
                              <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M20.4167 8.73656C20.4096 7.96933 20.1004 7.23579 19.5562 6.6949L13.3 0.424073C13.0268 0.152457 12.6571 0 12.2719 0C11.8866 0 11.517 0.152457 11.2437 0.424073C11.1071 0.559643 10.9986 0.720936 10.9245 0.898648C10.8505 1.07636 10.8124 1.26697 10.8124 1.45949C10.8124 1.65201 10.8505 1.84262 10.9245 2.02033C10.9986 2.19804 11.1071 2.35933 11.2437 2.4949L16.0417 7.27823H1.45833C1.07156 7.27823 0.700627 7.43188 0.427136 7.70537C0.153646 7.97886 0 8.34979 0 8.73656C0 9.12334 0.153646 9.49427 0.427136 9.76776C0.700627 10.0412 1.07156 10.1949 1.45833 10.1949H16.0417L11.2437 14.9928C10.9691 15.2655 10.8141 15.6361 10.8127 16.0231C10.8114 16.4101 10.9638 16.7817 11.2365 17.0563C11.5091 17.331 11.8797 17.486 12.2667 17.4874C12.6537 17.4887 13.0254 17.3363 13.3 17.0636L19.5562 10.7928C20.104 10.2483 20.4134 9.50886 20.4167 8.73656Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </LosseLink>
                    )
                  })}
                </div>
              </>
            )}
          </div>

          <div className="mt-10 flex justify-end">
            <button
              type="button"
              className="ml-3 flex h-8 w-8 items-center justify-center rounded-full disabled:opacity-25 lg:h-12 lg:w-12"
              disabled={!result?.pageInfo?.hasPreviousPage}
              onClick={() => {
                prev()
              }}
            >
              <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                <path
                  d="M1.66694e-06 5.5C-0.000374548 5.28465 0.041951 5.07135 0.124546 4.87236C0.207141 4.67336 0.328377 4.4926 0.481285 4.34046L4.83669 -5.08498e-08L6 1.15953L1.64541 5.5L6 9.84046L4.83669 11L0.482108 6.65953C0.329051 6.50746 0.207664 6.32673 0.124926 6.12774C0.0421889 5.92874 -0.000266325 5.71541 1.66694e-06 5.5Z"
                  fill="currentColor"
                />
              </svg>
            </button>
            <button
              type="button"
              className="ml-3 flex h-8 w-8 items-center justify-center rounded-full disabled:opacity-25 lg:h-12 lg:w-12"
              disabled={!result?.pageInfo?.hasNextPage}
              onClick={() => {
                next()
              }}
            >
              <svg width="3" height="6" viewBox="0 0 3 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-4 w-4">
                <path
                  d="M3 3C3.00019 3.11746 2.97902 3.23381 2.93773 3.34235C2.89643 3.45089 2.83581 3.54949 2.75936 3.63247L0.581654 6L-2.76463e-08 5.36753L2.17729 3L-2.34622e-07 0.632475L0.581653 1.79439e-08L2.75895 2.36753C2.83547 2.45047 2.89617 2.54905 2.93754 2.6576C2.97891 2.76614 3.00013 2.88251 3 3Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
