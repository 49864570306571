/* eslint-disable react-hooks/exhaustive-deps */
import type { Page_Flexcontent_Flex_Googlemaps } from '~/graphql/types'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { useCallback, useEffect, useState } from 'react'

export default function Googlemaps({ fields }: { fields: Page_Flexcontent_Flex_Googlemaps }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDJW7WAVwdYSxSWNIhUJ_pb7HJwvrma78Y'
  })

  const [map, setMap] = useState(null)
  const [markers, setMarkers] = useState<google.maps.Marker[]>([])

  useEffect(() => {
    if (!isLoaded || !map) return

    const markersArray = fields.locations?.edges
      // @ts-ignore
      ?.filter((location) => !!location.node.recap.location.latitude)
      ?.map((location) => {
        // @ts-ignore
        const cords = location.node.recap.location

        const marker = new google.maps.Marker({
          position: {
            lat: cords.latitude,
            lng: cords.longitude
          },
          map: map,
          icon: {
            url: '/static/marker.png'
          },
          // @ts-ignore
          title: location.node.title
        })
        return marker
      })

    setMarkers(markersArray as google.maps.Marker[])

    return () => {
      markers.forEach((marker) => marker.setMap(null))
      setMarkers([])
    }
  }, [isLoaded, map])

  const onLoad = useCallback(async function callback(curMap: any) {
    curMap.setOptions({
      disableDefaultUI: true
    })
    setMap(curMap)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  return (
    <section data-component="Googlemaps" className="section !pb-0">
      <div className="container">
        <div className="image--rounded h-[250px] sm:h-[300px] lg:h-[350px] xl:h-[600px]">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                height: 'calc(100% + 50px)',
                width: '100%'
              }}
              options={{
                styles: [
                  {
                    featureType: 'administrative.land_parcel',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  },
                  {
                    featureType: 'administrative.neighborhood',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  },
                  {
                    featureType: 'poi',
                    elementType: 'labels.text',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  },
                  {
                    featureType: 'poi.business',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  },
                  {
                    featureType: 'poi.park',
                    elementType: 'labels.text',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  },
                  {
                    featureType: 'water',
                    elementType: 'labels.text',
                    stylers: [
                      {
                        visibility: 'off'
                      }
                    ]
                  }
                ]
              }}
              center={{
                lat: 52.170617,
                lng: 5.387507
              }}
              zoom={8}
              onLoad={onLoad}
              id="locaties-map"
              mapContainerClassName="sm:h-[500px] h-[250px]"
              onUnmount={onUnmount}
            />
          )}
        </div>
      </div>
    </section>
  )
}
