import { LosseBlogBink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Post as PostType, Category, ContentNode, Page_Flexcontent_Flex_Overview } from '~/graphql/types'
import Post from './post-types/Post'
import Product from './post-types/Product'
import Slider, { Slide } from '~/components/elements/Slider'
import { Autoplay } from 'swiper/modules'

export default function OverviewPerCategory({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="OverviewPerCategory" className="section">
      <div className="container">
        <LosseBlogBink pageInfo={fields.posts?.pageInfo}>
          <Overview fields={fields} />
        </LosseBlogBink>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Overview) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post,
  Product: Product
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  const postTypeName = getPostTypeName(fields)

  return (
    <>
      {fields.filters
        ?.sort((a, b) => {
          const first = a?.recap?.sortorder || 0
          const second = b?.recap?.sortorder || 0

          return first - second
        })
        ?.map((edge) => {
          const category = edge as Category

          return (
            <div key={category.name} id={category.name || undefined} className="cta--block oldskool--shadow mb-12 bg-white overflow-hidden">
              <h2 className="title--h2 mb-8 xl:mb-12">{category.name}</h2>
              <div className="image--rounded hidden sm:flex mb-10 xl:mx-10 xl:mb-14">
                {edge?.recap?.gallery?.map((image) => {
                  return (
                    <div key={image?.mediaItemUrl} className="relative -ml-10 h-80 flex-grow last:-mr-10">
                      <LossePlaatjie src={image} className="image--rounded product--shadow--left absolute h-full w-full object-cover" />
                    </div>
                  )
                })}
              </div>
              <div className="sm:hidden">
                <Slider
                  loop
                  modules={[Autoplay]}
                  autoplay={{
                    delay: 8,
                    disableOnInteraction: false
                  }}
                  allowTouchMove={false}
                  speed={8000}
                  breakpoints={{
                    0: {
                      slidesPerView: 'auto'
                    }
                  }}
                  className="swiper-container-free-mode mb-8 !-mx-2 rounded-xl"
                >
                  {edge?.recap?.gallery?.map((image, index) => {
                    return (
                      <Slide key={index} className="!h-auto">
                        <LossePlaatjie src={image} className="aspect-[13/8] object-cover" />
                      </Slide>
                    )
                  })}
                </Slider>
              </div>

              {edge?.recap?.description && <Content className="content mb-10 xl:mx-10 xl:mb-14">{edge.recap.description}</Content>}

              <div className="grid gap-4 sm:grid-cols-2 sm:gap-8 lg:grid-cols-3 xl:grid-cols-4 xl:gap-10 xl:px-16">
                {fields.posts?.edges
                  ?.filter((edge) => {
                    const post = edge.node as PostType

                    return post.categories?.edges[0].node.databaseId === category.databaseId
                  })
                  .map((edge) => {
                    if (!edge || !edge.node) return null

                    const Component = PostTypes[postTypeName] || PostTypes.Post

                    return (
                      <div key={edge.node.uri} className="col-span-1">
                        <Component data={edge.node} />
                      </div>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </>
  )
}
