import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section data-component="FormContact" className="section">
      <div className="container">
        <div className="grid gap-12 sm:grid-cols-2 xl:px-24">
          <div className="col-span-1">
            <Content className="content">{fields.description}</Content>
          </div>
          <div className="form-contact oldskool--shadow cta--block relative col-span-1 overflow-hidden">
            <Form
              data={fields.form}
              scrollToConfirm={false}
              renderLoading={() => (
                <div className="absolute inset-0 flex items-center justify-center bg-white/75">
                  <Loading />
                </div>
              )}
              renderStatus={({ content }) => <Content>{content}</Content>}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
