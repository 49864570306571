import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="ContentDefault" className="section">
      <div className="container xl:max-w-screen-xl">
        <div className={clsx('grid grid-cols-1 gap-10 xl:gap-20', fields.styletype === 'default' ? 'sm:grid-cols-2' : 'sm:grid-cols-3')}>
          {fields.styletype === 'default' && fields.image && (
            <div className="order-2 flex items-center justify-center sm:col-span-1">
              <div className="image--rounded w-full max-w-sm sm:max-w-none">
                <LossePlaatjie src={fields.image} maxWidth={800} className="block h-full w-full" />
              </div>
            </div>
          )}
          {fields.styletype === 'block' && fields.block && (
            <aside className="order-4 flex items-start justify-center sm:col-span-1">
              <div className="aside--block">
                {fields.block.description && <Content className="content">{fields.block.description}</Content>}
                {fields.block.link && <LosseLink src={fields.block.link} className="btn mt-8" />}
              </div>
            </aside>
          )}
          {(fields.description || fields.link) && (
            <div
              className={clsx(
                'order-1 flex items-center',
                fields.styletype === 'default' ? 'sm:col-span-1' : 'sm:col-span-2',
                fields.textPosition === 'left' ? 'sm:order-1' : 'sm:order-3',
                fields.textStyle === 'block' && 'cta--block oldskool--shadow'
              )}
            >
              <div>
                {fields.description && (
                  <article>
                    <Content className="content">{fields?.description}</Content>
                  </article>
                )}

                {fields.link && <LosseLink src={fields.link} className="btn mt-8" />}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
