import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'
import { LosseLayout, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import clsx from 'clsx'

export default function Layout({ children }: PropsWithChildren<{}>) {
  const { isSticky } = useLosseLayout()
  const location = useLocation()

  useEffect(() => {
    if (!location.hash) return

    const element = document.getElementById(decodeURI(location.hash).replace('#', ''))

    if (!element) return

    setTimeout(() => {
      element.scrollIntoView()
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <LosseLayout>
      <Header />
      <main className={clsx('min-h-screen', isSticky ? 'pt-[80px] lg:pt-[88px]' : 'pt-[112px] lg:pt-[114px]')}>{children}</main>
      <Footer />
    </LosseLayout>
  )
}
