export default function MagnifyingGlass({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className={className ?? undefined}>
      <path
        fill="#D9D9D9"
        d="M23.561 21.445l-4.645-4.647c3.476-4.644 2.528-11.227-2.117-14.703C12.155-1.381 5.572-.433 2.096 4.212-1.38 8.856-.432 15.439 4.213 18.915a10.504 10.504 0 0012.586 0l4.647 4.647a1.495 1.495 0 002.115-2.114v-.003zm-13.016-3.427a7.474 7.474 0 117.474-7.474 7.483 7.483 0 01-7.474 7.474z"
      />
    </svg>
  )
}
