/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import { motion, AnimatePresence } from 'framer-motion'

export default function HeaderMobile() {
  const { setScrollable } = useLosseLayout()
  const location = useLocation()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setOpen(false)
    }
  }, [location.pathname, location.hash, location.search])

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        onClick={() => {
          setOpen(!isOpen)
          setScrollable(false)
        }}
        className="z-10 flex items-center"
        title="Open menu"
      >
        <div className="mr-2 text-sm font-bold uppercase">Menu</div>
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gp-orbeeltle">
          <div className="w-full">
            <div className="mx-auto my-[4px] h-[3px] w-[55%] bg-white" />
            <div className="mx-auto my-[4px] h-[3px] w-[55%] bg-white" />
            <div className="mx-auto my-[4px] h-[3px] w-[55%] bg-white" />
          </div>
        </div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed left-0 top-0 z-[99] flex h-screen w-screen items-center justify-center bg-gp-joltik shadow-2xl"
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.25 }}
          >
            <div className="absolute inset-x-0 top-5">
              <div className="container flex justify-end">
                <button
                  className="flex items-center text-center"
                  type="button"
                  onClick={() => {
                    setOpen(!isOpen)
                    setScrollable(true)
                  }}
                  aira-label="Close menu"
                >
                  <div className="mr-2 text-sm font-bold uppercase">Sluiten</div>
                  <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gp-orbeeltle">
                    <div className="flex w-full justify-center text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <ul className="m-0 w-8/12 pl-0">
              <HeaderMenu />
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
