import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import { motion } from 'framer-motion'
import type { Page_Flexcontent_Flex_Gallery } from '~/graphql/types'
import { useState } from 'react'

export default function GalleryCards({ fields }: { fields: Page_Flexcontent_Flex_Gallery }) {
  const [open, setOpen] = useState(-1)

  return (
    <section data-component="GalleryCards" className="section--sm">
      <div className="container">
        <div className="oldskool--shadow cta--block lg:p-10 xl:p-16">
          <Content className="content">{fields.description}</Content>
          <div className="rounded--borders mt-6 mb-3 h-[calc(100%-20px)] overflow-hidden lg:my-12 xl:mx-20">
            {fields.items?.map((edge, index) => {
              return (
                <motion.div
                  key={edge?.link?.title}
                  className={clsx(index !== 0 && '-mt-8', 'rounded--fix product--shadow--top rounded--borders overflow-hidden last:-mb-8')}
                  onHoverStart={() => {
                    setOpen(index)
                  }}
                  onHoverEnd={() => {
                    setOpen(-1)
                  }}
                  onClick={() => {
                    setOpen(index)
                  }}
                >
                  <motion.div
                    className="py-0"
                    animate={open === index ? 'open' : 'closed'}
                    variants={{
                      open: {
                        paddingTop: 100,
                        paddingBottom: 100
                      },
                      closed: {
                        paddingTop: 0,
                        paddingBottom: 0
                      }
                    }}
                  >
                    <LossePlaatjie maxWidth={1400} src={edge?.image} className="absolute top-0 left-0 h-full w-full object-cover" />

                    <div className="absolute inset-0 bg-black/20" />
                    <div className="flex-center relative inset-0 z-10">
                      <LosseLink
                        src={edge?.link}
                        className={clsx('btn--pseudo -mt-8 p-14 text-center font-bold text-white lg:p-16 lg:text-lg xl:p-20')}
                      />
                    </div>
                  </motion.div>
                </motion.div>
              )
            })}
          </div>
          <div className="mt-3 flex flex-wrap">
            {fields.links?.map((edge) => {
              return <LosseLink key={edge?.link?.title} className="btn mr-4 mt-3" src={edge?.link} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
