import { LosseKruumels } from '@ubo/losse-sjedel'

export default function Breadcrumbs() {
  return (
    <LosseKruumels
      type="fragment"
      className="breadcrumbs text-sm children-a:inline-block children-a:first-letter:uppercase hover:children-a:underline children-li:flex children-li:items-center children-ol:flex children-ol:items-center md:text-base [&_li:last-child]:font-bold"
      seperator={
        <span className="mx-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-4 md:w-5"
          >
            <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
        </span>
      }
    />
  )
}
