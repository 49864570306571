import { LosseBlogBink } from '@ubo/losse-sjedel'
import type {
  ContentNodeConnectionEdge,
  Location as LocationType,
  ContentNode,
  Page_Flexcontent_Flex_Overview,
  PageInfo
} from '~/graphql/types'
import Post from './post-types/Post'
import Location from './post-types/Location'

export default function OverviewLocations({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="OverviewLocations" className="section">
      <div className="container">
        <LosseBlogBink pageInfo={fields.posts?.pageInfo as PageInfo}>
          <Overview fields={fields} />
        </LosseBlogBink>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Overview) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post,
  Location: Location
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Overview }) {
  const postTypeName = getPostTypeName(fields)

  const locations = {
    monday: fields.posts?.edges.filter((edge) => {
      const location = edge.node as LocationType

      return location.recap?.monday?.present
    }),
    tuesday: fields.posts?.edges.filter((edge) => {
      const location = edge.node as LocationType

      return location.recap?.tuesday?.present
    }),
    wednesday: fields.posts?.edges.filter((edge) => {
      const location = edge.node as LocationType

      return location.recap?.wednesday?.present
    }),
    thursday: fields.posts?.edges.filter((edge) => {
      const location = edge.node as LocationType

      return location.recap?.thursday?.present
    }),
    friday: fields.posts?.edges.filter((edge) => {
      const location = edge.node as LocationType

      return location.recap?.friday?.present
    }),
    saturday: fields.posts?.edges.filter((edge) => {
      const location = edge.node as LocationType

      return location.recap?.saturday?.present
    })
  }

  return (
    <>
      <Day title="Maandag" day={locations.monday} postTypeName={postTypeName} />
      <Day title="Dinsdag" day={locations.tuesday} postTypeName={postTypeName} />
      <Day title="Woensdag" day={locations.wednesday} postTypeName={postTypeName} />
      <Day title="Donderdag" day={locations.thursday} postTypeName={postTypeName} />
      <Day title="Vrijdag" day={locations.friday} postTypeName={postTypeName} />
      <Day title="Zaterdag" day={locations.saturday} postTypeName={postTypeName} />
    </>
  )
}

function Day({ title, day, postTypeName }: { title: string; day: ContentNodeConnectionEdge[] | undefined; postTypeName: string }) {
  if (!day || day.length === 0) return null

  return (
    <div className="cta--block oldskool--shadow mb-12 lg:p-12">
      <h2 className="title--h2">{title}</h2>
      <div className="py-8 xl:py-12 xl:px-16">
        <div className="grid gap-10 overflow-hidden sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {day?.map((edge) => {
            if (!edge || !edge.node) return null

            const location = edge.node as LocationType

            const Component = PostTypes[postTypeName] || PostTypes.Post

            let recap
            switch (title.toLowerCase()) {
              case 'maandag':
                recap = location.recap?.monday
                break
              case 'dinsdag':
                recap = location.recap?.tuesday
                break
              case 'woensdag':
                recap = location.recap?.wednesday
                break
              case 'donderdag':
                recap = location.recap?.thursday
                break
              case 'vrijdag':
                recap = location.recap?.friday
                break
              case 'zaterdag':
                recap = location.recap?.saturday
                break
            }

            return (
              <div key={edge.node.uri} className="relative col-span-1">
                <Component data={location} recap={recap} title={title} />
                <div className="absolute inset-x-0 -bottom-6 h-[2px] bg-gp-galarian-corsola sm:inset-y-0 sm:-right-2 sm:w-[2px]"></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
