import { Form } from '@ubo/losse-sjedel'
import { motion } from 'framer-motion'
import { useState } from 'react'
import MagnifyingGlass from './svg/MagnifyingGlass'

export default function InputSearch() {
  const [searchState, setSearchState] = useState({
    query: '',
    hover: false,
    focus: false
  })
  return (
    <div className="my-2 flex items-center lg:my-0">
      <Form
        action="zoeken"
        method="get"
        className="group relative flex items-center"
        onMouseLeave={() => setSearchState({ ...searchState, hover: false })}
        onMouseEnter={() => setSearchState({ ...searchState, hover: true })}
      >
        <motion.input
          id="search-input"
          onChange={(e) => {
            setSearchState({ ...searchState, query: e.target.value })
          }}
          onFocus={() => {
            setSearchState({ ...searchState, focus: true })
          }}
          onBlur={() => {
            setSearchState({ ...searchState, focus: false })
          }}
          type="text"
          placeholder="Zoeken"
          name="q"
          className="focus:border-ssi-crawdaunt w-52 rounded-full border-2 border-white px-6 py-2 lg:w-36 xl:w-52"
        />
        <button type="submit" className="mt-[0.125rem] -ml-10 mr-4">
          <MagnifyingGlass />
        </button>
      </Form>
    </div>
  )
}
