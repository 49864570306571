import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function ContentSimple({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section data-component="ContentSimple" className="section">
      <div className="container">
        <Content className="content">{fields.description}</Content>
      </div>
    </section>
  )
}
