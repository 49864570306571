import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import { motion } from 'framer-motion'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <nav
      className={clsx(
        isSticky ? 'h-[80px] py-1 shadow lg:h-[88px]' : 'h-[112px] py-4 lg:h-[114px]',
        'fixed left-0 right-0 top-0 z-50 bg-gp-joltik transition-all'
      )}
    >
      <motion.div
        className="absolute inset-0 -top-[4px] z-[99] h-1 bg-gp-rookidee lg:top-0 lg:h-2"
        animate={isSticky ? 'hide' : 'show'}
        variants={{
          hide: {
            y: -60
          },
          show: {
            y: 0
          }
        }}
        transition={{
          duration: 0.2
        }}
      >
        <div className="container relative">
          <div className="absolute right-3 top-0 flex items-center justify-center rounded-b-xl bg-gp-rookidee px-4 py-1 text-sm font-bold text-white lg:right-0 xl:rounded-b-2xl xl:px-5 xl:py-2">
            <img src="/static/halal.png" alt="Halal" className="mr-2 hidden h-8 w-8 xl:block" />
            100% Halal Kip
          </div>
        </div>
      </motion.div>
      <div className="container relative flex h-full items-center justify-between bg-gp-joltik">
        <LosseLink to="/" aria-label="Home" className={clsx(isSticky ? 'lg:h-20' : 'h-20 lg:h-full', 'block w-auto')}>
          <img src="/static/logo.png" alt="" className="h-full w-36 object-contain transition-all max-lg:max-h-[80%] lg:w-56" />
        </LosseLink>

        <HeaderDesktop />
        <HeaderMobile />
        <motion.div
          className="absolute -bottom-3 right-0 h-1 bg-gp-rookidee max-lg:top-[76px] lg:right-6 lg:h-2"
          animate={!isSticky ? 'hide' : 'show'}
          variants={{
            hide: {
              opacity: 0,
              y: -120
            },
            show: {
              y: 0,
              opacity: 1
            }
          }}
          transition={{
            duration: 0.2
          }}
        >
          <div className="container relative">
            <div className="absolute right-3 top-0 flex w-[185.95px] items-center justify-center rounded-b-xl bg-gp-rookidee px-4 py-1 text-sm font-bold text-white lg:right-0 xl:rounded-b-2xl xl:px-5 xl:py-2">
              <img src="/static/halal.png" alt="Halal" className="mr-2 hidden h-8 w-8 xl:block" />
              100% Halal Kip
            </div>
          </div>
        </motion.div>
      </div>
    </nav>
  )
}
