import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import { Autoplay, Pagination } from 'swiper/modules'

export default function BannerGallery({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerGallery" className="section">
      <div className="container">
        <div className="relative">
          <div className="image--rounded">
            <Slider
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 7500
              }}
              pagination={{ type: 'bullets', clickable: true }}
              className="aspect-video bg-gp-electabuzz"
            >
              {fields.gallery?.map((image) => {
                return (
                  <Slide key={image?.mediaItemUrl} className="!h-auto">
                    <LossePlaatjie src={image} className="h-full w-full object-cover" />
                    <div className="absolute inset-0 touch-none bg-gp-gradient-dark" />
                  </Slide>
                )
              })}
            </Slider>
          </div>
          <div className="inset-0 z-10 mt-6 lg:absolute lg:mt-0 lg:w-[55%]">
            <div className="relative flex h-full w-full flex-col justify-center sm:px-10">
              <div className="relative">
                <Content className="content lg:text-white xl:children-headings:!text-6xl">{fields.description}</Content>
                <div className="max-lg:[&>a]:btn lg:[&>a:nth-child(even)]:btn--white lg:[&>a:nth-child(odd)]:btn mt-4 flex flex-wrap lg:mt-12">
                  {fields.links?.map((edge) => {
                    return <LosseLink key={edge?.link?.title} src={edge?.link} className="mr-5 mb-2" />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
