export default function Email({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19" className={className ?? undefined}>
      <g clipPath="url(#clip0_11_171)">
        <path
          fill="currentColor"
          d="M14.646.792H4.354A4.359 4.359 0 000 5.146v8.708a4.359 4.359 0 004.354 4.354h10.292A4.36 4.36 0 0019 13.854V5.146A4.36 4.36 0 0014.646.792zm0 2.375c.48.001.943.179 1.3.499l-5.145 5.146a1.879 1.879 0 01-2.595 0L3.06 3.666a1.96 1.96 0 011.294-.5h10.292zm0 12.666H4.354a1.979 1.979 0 01-1.979-1.979V6.347l4.148 4.147a4.211 4.211 0 005.954 0l4.148-4.147v7.507a1.98 1.98 0 01-1.98 1.98z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_11_171">
          <path fill="currentColor" d="M0 0H19V19H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
