import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import GalleryDefault from './GalleryDefault'
import GalleryCards from './GalleryCards'

export default function GalleryShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: GalleryDefault,
    cards: GalleryCards
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
