/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import Content from '~/components/elements/Content'
import type { Location as Props, Location_Recap_Tuesday } from '~/graphql/types'

export default function Location({ data, recap, title }: { data: Props; recap: Location_Recap_Tuesday; title: string }) {
  const [active, setActive] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.hash.replace('#', '') === data.title) {
      setActive(true)
    }
  }, [location.hash])

  if (!data.title) return null

  return (
    <div
      className={clsx(
        active ? 'border-gp-orbeeltle p-4' : 'border-transparent',
        'border-2 rounded-xl flex h-full flex-col justify-between'
      )}
      id={data.title}
    >
      <div>
        <h2 className="text-lg font-bold lg:text-xl">{data.title}</h2>
        <Content className="content mt-4">{recap?.info?.location}</Content>
        <time className="block">{`${title} ${recap?.info?.time?.from} tot ${recap?.info?.time?.to} uur`}</time>
      </div>
      <div>
        <a href={`tel:${recap?.info?.phone}`} className="btn mt-10 items-center text-sm xl:text-base" target="_blank" rel="noreferrer">
          {recap?.info?.phone}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="ml-2 h-4 w-4"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
        </a>
      </div>
    </div>
  )
}
